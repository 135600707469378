@import "../colors";

.toggle {
  &-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  position: relative;
  display: inline-block;
  width: 2.5em;
  height: 1.25em;
  cursor: pointer;

  &__slider {
    position: absolute;
    top: 0em;
    // left: 0.2em;
    width: 1.25em;
    height: 1.25em;
    background-color: $secondary-50-color;
    border-radius: 50%;
    transition: transform 0.2s ease-in-out;
  }

  background-color: $secondary-20-color;
  border-radius: 1.5em;
  transition: background-color 0.2s ease-in-out;

  &--checked {
    background-color: $status-success-100-color-opacity-50;
    .toggle__slider {
      transform: translateX(1.3em);
      background-color: $status-success-80-dark-color;
    }
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &__label {
    display: flex;
    align-items: center;
    font-size: 1em;
  }
}
