@import "../designSystem/colors";

.finding {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__loading {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > img {
      width: 4rem;
      animation: 1s linear infinite inProgress;
      rotate: 360deg;
      transition: rotate 2s;
    }
  }
  &__content {
    flex: 1;
    display: grid;
    grid-template-columns: minmax(266px, 34%) minmax(551px, calc(66% - 1px));
    grid-template-areas: "finding__summary finding__details";
  }
}

.accept-finding {
  &-resume {
    .accept-finding__information {
      &-text {
        margin-bottom: 20px;
      }
      &-subtitle {
        color: $secondary-100-color;
      }
    }
  }
  &__information {
    margin-bottom: 25px;
    &-subtitle {
      color: $secondary-60-color;
      font-size: 14px;
    }
  }
  &__period {
    margin-bottom: 25px;
    .label {
      display: inline-block;
      margin-bottom: 5px;
    }
  }
  &__notes {
    &-label {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin-bottom: 5px;
    }
    span {
      margin-top: 5px;
      float: right;
      margin-left: auto;
      color: $secondary-40-color;
      font-size: 12px;
    }
  }
  .label--bold {
    font-weight: 600;
  }
}
